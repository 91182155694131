import './Settings.css';
import React from 'react';
import {Link} from "react-router-dom";

class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.timerInput = React.createRef();
    this.namesInput = React.createRef();
    this.citiesInput = React.createRef();
  }

  render() {
    let namesSplitIntoLines = "";
    let citiesSplitIntoLines = "";
    this.props.names.forEach(name => {
      namesSplitIntoLines += `${name}\n`;
    })
    this.props.cities.forEach(city => {
      citiesSplitIntoLines += `${city}\n`;
    })
    return (
      <div className="settingsContainer">
        <h1>Настройки</h1>
        <div className="oneSetting">
          <p>Таймер: сколько секунд отсчитывать</p>
          <input ref={this.timerInput} defaultValue={this.props.timer} />
        </div>
        <div className="oneSetting">
          <p>Имена задающих вопрос</p>
          <textarea ref={this.namesInput} defaultValue={namesSplitIntoLines}></textarea>
        </div>
        <div className="oneSetting">
          <p>Города задающих вопрос</p>
          <textarea ref={this.citiesInput} defaultValue={citiesSplitIntoLines}></textarea>
        </div>
        <div>
          <Link to="/"><button onClick={() => {this.props.saveFunction(this.namesInput.current.value, this.citiesInput.current.value, this.timerInput.current.value)}}>Сохранить и выйти</button></Link>
          <Link to="/"><button>Выйти без сохранения</button></Link>
        </div>
      </div>
    );
  }
}

export default Settings;
