import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Settings from './Settings';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      leftCounter: 0,
      rightCounter: 0,
      showPerson: false,
      names: [],
      cities: [],
      timerValue: null,
      timerSetting: 90,
      timerHandle: null,
      soundObject: null,
      soundActiveButton: null,
    };
    this.stopMusic = this.stopMusic.bind(this);
  }

  stopMusic() {
    if (this.state.soundObject) {
      this.state.soundObject.removeEventListener("pause", this.stopMusic);
      this.state.soundObject.pause();
      this.state.soundObject.remove();
      this.state.soundActiveButton.classList.remove('buttonGroup__button_active');
      this.setState({
        soundObject: null,
        soundActiveButton: null
      });
    }
  }

  playSound(button, name) {
    this.stopMusic();
    let audio = new Audio(`/sounds/${name}.mp3`);
    button.classList.add('buttonGroup__button_active');
    this.setState(
      {soundObject: audio, soundActiveButton: button}, () => {
      this.state.soundObject.play();
      this.state.soundObject.addEventListener("pause", this.stopMusic);
    })
  }

  incrementTimer() {
    let oldValue = this.state.timerValue;
    let newValue = oldValue - 1;
    if (newValue === 0) {
      newValue = null;
      this.stopTimer();
    } else if (newValue === 10) {
      let audio = new Audio('/sounds/beep-high-pitch.mp3');
      audio.play();
    }
    this.setState({ timerValue: newValue });
  }

  startTimer() {
    if (!this.state.timerHandle) {
      this.setState({
        timerValue: this.state.timerSetting,
        timerHandle: setInterval(() => { this.incrementTimer() }, 1000)
      });
      let audio = new Audio('/sounds/beep.mp3');
      audio.play();
    }
  }

  stopTimer() {
    if (this.state.timerHandle) {
      clearTimeout(this.state.timerHandle);
      this.setState({ timerHandle: null });
      let audio = new Audio('/sounds/beep.mp3');
      audio.play();
    }
  }

  togglePerson() {
    this.state.showPerson ? this.setState({showPerson: false}) : this.setState({showPerson: true});
  }

  changeSide(side, incrementBy) {
    let oldValue;
    if (side === "left") oldValue = this.state.leftCounter
    else if (side === "right") oldValue = this.state.rightCounter;

    let newValue = oldValue + incrementBy;
    if (newValue < 0) newValue = 0;

    if (side === "left")
      this.setState(
        { leftCounter: newValue }
      )
    else if (side === "right")
      this.setState(
        { rightCounter: newValue }
      )
  }

  componentDidMount() {
    fetch("names.txt")
      .then(res => res.text())
      .then(res => {
          this.setState({
            names: this.parseFromLinesToArray(res),
          });
        },
      )

    fetch("cities.txt")
      .then(res => res.text())
      .then(res => {
          this.setState({
            cities: this.parseFromLinesToArray(res),
          });
        },
      )
  }

  parseFromLinesToArray(lines) {
    let resultArray = [];
    lines = lines.trim().split(/\r?\n/);
    lines.forEach(line => {
      if (line !== "") resultArray.push(line);
    })
    return resultArray;
  }

  getRandomName() {
    return this.state.names[Math.floor(Math.random() * this.state.names.length)];
  }

  getRandomCity() {
    return this.state.cities[Math.floor(Math.random() * this.state.cities.length)];
  }

  myTest() {
    return 1;
  }

  saveSettings = (newNames, newCities, newTimer) => {
    this.setState({
      names: this.parseFromLinesToArray(newNames),
      cities: this.parseFromLinesToArray(newCities),
      timerSetting: newTimer,
    });
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/settings">
            <Settings names={this.state.names} cities={this.state.cities} timer={this.state.timerSetting} saveFunction={this.saveSettings}/>
          </Route>
          <Route path="/">
            <div className="App">
              {this.state.timerHandle &&
                <div className="modal">
                  <div>
                    <h1>Осталось {this.state.timerValue} сек.</h1>
                  </div>
                </div>
              }
              {(this.state.showPerson && !this.state.timerHandle) &&
                <div className="modal">
                  <div>
                    <h1>Против вас играет:</h1>
                    <h1>{this.getRandomName()}</h1>
                    <h1>{this.getRandomCity()}</h1>
                  </div>
                </div>
              }
              <div className="oneSide oneSide_left">
                <h1>{this.state.leftCounter}</h1>
                <div className="buttons">
                  <button onClick={() => { this.changeSide("left", 1) }}><i className ="fas fa-plus" /></button>
                  <button onClick={() => { this.changeSide("left", -1) }}><i className ="fas fa-minus" /></button>
                </div>
              </div>
              <div className="oneSide oneSide_right">
                <h1>{this.state.rightCounter}</h1>
                <div className="buttons">
                  <button onClick={() => { this.changeSide("right", 1) }}><i className ="fas fa-plus" /></button>
                  <button onClick={() => { this.changeSide("right", -1) }}><i className ="fas fa-minus" /></button>
                </div>
              </div>
              <div className="buttonsPanel">
                <div className="buttonsGroup__header"><i className="fas fa-music"></i></div>
                <button className="buttonsGroup__button" onClick={() => { this.stopMusic() }}>тишина</button>
                <button className="buttonsGroup__button" onClick={(e) => { this.playSound(e.target, "gong") }}><i className="fas fa-volume-up"></i> гонг</button>
                <button className="buttonsGroup__button" onClick={(e) => { this.playSound(e.target, "volchok") }}><i className="fas fa-volume-up"></i> волчок</button>
                <button className="buttonsGroup__button" onClick={(e) => { this.playSound(e.target, "intro") }}><i className="fas fa-volume-up"></i> начало</button>
                <button className="buttonsGroup__button" onClick={(e) => { this.playSound(e.target, "music1") }}><i className="fas fa-volume-up"></i> музыка 1</button>
                <button className="buttonsGroup__button" onClick={(e) => { this.playSound(e.target, "music2") }}><i className="fas fa-volume-up"></i> музыка 2</button>
                <button className="buttonsGroup__button" onClick={(e) => { this.playSound(e.target, "music3") }}><i className="fas fa-volume-up"></i> музыка 3</button>
                <button className="buttonsGroup__button" onClick={(e) => { this.playSound(e.target, "music4") }}><i className="fas fa-volume-up"></i> музыка 4</button>
                <button className="buttonsGroup__button" onClick={(e) => { this.playSound(e.target, "outro") }}><i className="fas fa-volume-up"></i> финал</button>
                <div className="buttonsGroup__header"><i className="fas fa-clock"></i></div>
                <button className="buttonsGroup__button" onClick={() => { this.startTimer() }}>старт</button>
                <button className="buttonsGroup__button" onClick={() => { this.stopTimer() }}>стоп</button>
                <div className="buttonsGroup__header"><i className="fas fa-dice-six"></i></div>
                <button className="buttonsGroup__button" onClick={() => { this.togglePerson() }}>кто задал вопрос</button>
                <Link className="buttonsGroup__button" to="/settings"><button >настройки</button></Link>
              </div>
            </div>
          </Route>

        </Switch>
      </Router>
    );
  }
}

export default App;
